module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-theme-switcher/gatsby-browser.js'),
      options: {"plugins":[],"defaultDarkTheme":"theme-blue","defaultLightTheme":"theme-origdefault","minify":true,"themeStorageKey":"theme"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Unreal Toys","short_name":"UnrealToys","description":"Toy vending machines, bouncy ball machines, capsule toys, gacha, bandia and tomy style machines","start_url":"/","background_color":"#6dc3fd","theme_color":"#FFFFFF","display":"standalone","icon":"static/images/logo/logo-lg.svg","theme_color_in_head":false,"crossOrigin":"use-credentials","legacy":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"759dd77efda8ca45b9c58655b846266e"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"precachePages":["/","/machines/*","/signup/*","/team","/about","/contact"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-XXX-1","head":false,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0,"enableWebVitalsTracking":false},
    }]
